<template>
	<div id="mainpage" class="flex flex-col min-h-screen home">
		<SiteHeader />

		<header class="relative w-full">
			<!--Main-Banner-->
			<div class="container h-full">
				<div class="flex flex-col text-center text-white uppercase mt-18 md:text-left lg:mt-28 xl:mt-36">
					<div class="text-xl font-light leading-tight font-JosefinSans lg:text-6xl">HOH LAW CORPORATION</div>
					<div class="mb-5 text-6xl font-normal leading-none font-GildaDisplay md:text-12xl lg:text-16xl">LAW FIRM IN SINGAPORE WITH <span class="text-siteblue">8 BRANCHES</span></div>
					<div class="block text-xs font-semibold tracking-widest font-JosefinSans">With more than 30 years of extensive experience, HOH Law Corporation is well-versed in all Criminal related matters. Rest assured that you are in good, capable, and trusted hands when you engage Hoh Law Corporation.</div>
					<div class="flex flex-col mt-16 mb-20 md:mt-10 md:flex-row">
						<a href="tel:+6565534800" class="flex items-center justify-center px-6 mx-auto mb-5 text-xs leading-none border border-white rounded-full font-JosefinSans w-65 h-13 md:mx-0 md:mr-5 md:mb-0 lg:text-tiny">
							<div class="mr-2 font-semibold tracking-widest text-white uppercase">call us at</div>
							<span class="block text-base font-semibold tracking-widest text-siteblue">6553 4800</span>
						</a>
						<a href="#enquiry" v-smooth-scroll class="flex items-center justify-center px-6 mx-auto text-xs font-semibold leading-none tracking-widest text-white uppercase transition-all duration-200 rounded-full w-65 h-13 bg-siteblue font-JosefinSans hover:bg-sitegreen-600 md:mx-0 lg:text-tiny"><span>enquire now</span></a>
					</div>
				</div>
			</div>
			<!--Main-Banner End-->
		</header>

		<div class="relative w-full overflow-hidden bg-sitelightblue-400">

			<div class="pt-8 mx-auto lg:pt-12 max-width-1400">
				<div class="container" id="about-us">
					<div class="flex flex-col items-center pb-5 md:flex-row md:flex-between">
						<div class="text-center md:text-left">
						<div class="tracking-widest uppercase text-sitelightblue-600 font-JosefinSans md:text-xl">Why Choose HOH Law Corporation</div>
						<div class="mb-5 text-2xl font-normal leading-none uppercase font-GildaDisplay text-sitemidblue-600 md:text-8xl">For Your Criminal Matter?</div>
						</div>
						<div class="mx-auto md:ml-auto md:mr-0">
							<a href="tel:+6565534800" class="inline-block w-10 h-10 mr-2 transition-all duration-200 bg-center bg-contain rounded-md bg-sitelightblue hover:bg-sitelightblue-600 md:mr-3 lg:w-14 lg:h-14" :style="'background-image: url(' + require('@/assets/images/phone-icon2.png') + ')'">
							<span class="hidden">+6565534800</span>
							</a>
							<a href="#enquiry" v-smooth-scroll class="inline-block w-10 transition-all duration-200 rounded-md bg-sitemidblue hover:bg-sitemidblue-600 lg:w-14">
								<img src="~@/assets/images/email-icon.png" alt="" width="60" height="60">
							</a>
						</div>
					</div>
				</div>
				<div class="hidden w-full h-1 border-b border-sitedarkblue md:block"></div>
				<div class="container pb-4 mx-auto md:pt-8">
					<div class="flex flex-wrap pb-4 mt-2 -mx-8 lg:mt-2 xl:mt-6">
					<div class="w-full px-8 mb-10 leading-8 lg:w-full">
						<!-- <div class="flex justify-center">
							<img src="~@/assets/images/group.jpg" alt="Team Memebers" class="w-full rounded-md lg:w-3/5 md:rounded-lg" loading="lazy">
						</div> -->
						<ol class="pl-4 mt-5" style="list-style:decimal;">
							<li>We offer <strong>FREE</strong> consultation for criminal matters, with an <strong>in-depth discussion</strong> on your matter (with no obligation).</li>
							<li>We have 8 branches located all over Singapore for YOUR <strong>convenience</strong> and <strong>accessibility</strong>.</li>
							<li>Submit your details below and be instantly connected to the lawyer located nearest to you.</li>
							<li>Team of lawyers and retired ex-police officers to assist in investigation work.</li>
						</ol>
					</div>
					</div>
				</div>
			</div>

			<div class="pb-8 lg:pb-16 mx-auto max-width-1400">
				<div class="container md:pb-16 lg:pb-16">
					<picture>
						<source type="image/png" media="(min-width:1024px)" srcset="~@/assets/images/banner-simco01.png" width="1767" height="485">
						<img class="w-full h-auto" src="~@/assets/images/banner-simco01-m.png" alt="" width="693" height="384">
					</picture>
				</div>
			</div>

			<div class="pb-8 mx-auto max-width-1400">
				<div class="relative z-10 px-10 pb-0 bg-sitelightblue pt-15 md:px-20 md:rounded-lg md:-mt-18">
					<!--Content-->
					<div id="trafficaccidents" class="mb-5 text-2xl font-normal leading-none uppercase font-GildaDisplay text-sitemidblue-600 md:text-8xl">
						Criminal Offences
					</div>
					<div class="w-full pb-10">
						Here are some types of criminal offences we specialise in:
					</div>
					<div class="order-first w-full pb-10">
						<img src="~@/assets/images/img01.jpg" alt="" class="w-full h-auto rounded-md md:rounded-lg" width="1237" height="511" loading="lazy">
					</div>
					<div class="flex flex-col pb-10 -mx-4 overflow-hidden border-b-4 lg:flex-row border-siteblue">
						<div class="w-full px-4 md:w-full lg:w-1/2">
							<ol class="pl-4" style="list-style:disc;">
							<li>Outrage of Modesty</li>
							<li>Sexual Offences</li>
							<li>Theft, Shoplifting & Criminal Breach of Trust</li>
							<li>Drink Driving & Other Traffic Offences</li>
							<li>Drug Offences</li>
							</ol>
						</div>
						<div class="w-full px-4 md:w-full lg:w-1/2">
							<ol class="pl-4" style="list-style:disc;">
							<li>Fighting/Violence Offences</li>
							<li>Criminal Intimidation</li>
							<li>Causing Hurt & Rash Acts Offences</li>
							<li>Immigration Offences</li>
							<li>Other Criminal Offences</li>
							</ol>
						</div>
					</div>
					<!--Content-->
				</div>
			</div>

			<div class="pt-2 mx-auto mb-8 max-width-1400">
				<div class="container flex flex-col pt-4 mx-auto lg:pt-8 lg:flex-row" id="about-us">
					<div class="text-center">
						<div class="pb-6 text-3xl leading-tight tracking-normal uppercase text-black font-GildaDisplay md:text-10xl">
							Consistently voted amongst the Best law firms in Singapore
						</div>
					</div>
					<!-- <div class="w-full lg:w-1/2">
						<div class="text-center md:text-left">
							<div class="pb-6 text-3xl leading-tight tracking-normal uppercase text-sitelightblue-600 font-GildaDisplay md:text-10xl">Consistently voted amongst the Best law firms in Singapore</div>
							<div class="mb-5 text-2xl font-normal leading-tight tracking-normal uppercase font-GildaDisplay text-sitemidblue-600 md:text-7xl">Survey by Straits Times and Statista</div>
						</div>
					</div>
					<div class="w-full lg:w-1/2">
						<div class="grid grid-cols-2 gap-4 leading-8">
							<img class="w-full" src="~@/assets/images/the-straits-times-2021.jpg" alt="" width="350" height="344" loading="lazy" >
							<img class="w-full" src="~@/assets/images/the-straits-times-2022.jpg" alt="" width="350" height="285" loading="lazy" >
						</div>
					</div> -->
				</div>
			</div>

			<!--First Section-->

			<!-- <div class="container" id="workplace-accidents">
				<div class="flex flex-col items-center pb-5 pt-15">
					<div id="WorkplaceCompensation" class="text-center">
					<div class="text-2xl tracking-widest uppercase text-sitemidblue-600 font-GildaDisplay md:text-8xl">WORKPLACE ACCIDENTS</div>
					<div class="mb-5 font-normal leading-none uppercase font-JosefinSans text-sitelightblue-600 md:text-xl">(Work Injury Compensation Act (WICA) Claims)</div>
					</div>
				</div>
			</div>
			<div class="container md:pb-15">
				<div class="relative pb-0 bg-white md:px-20 pt-15 md:rounded-lg">
					<div class="w-full px-4 pb-10 lg:px-0">
						<img src="~@/assets/images/img02.png" alt="" class="mx-auto mb-5 rounded-md md:rounded-lg">
					</div>
					<div class="flex flex-col px-4 pb-10 -mx-4 overflow-hidden border-b-4 lg:flex-row lg:px-0 border-siteblue">
						<div class="w-full px-4 md:w-full lg:w-1/2">
							<ol class="pl-4" style="list-style:disc;">
							<li>Fall from height</li>
							<li>Falling objects </li>
							<li>Tools, machinery </li>
							</ol>
						</div>
						<div class="w-full px-4 md:w-full lg:w-1/2">
							<ol class="pl-4" style="list-style:disc;">
							<li>Crane/Machinery operations </li>
							<li>Injuries caused by fellow co-workers</li>
							</ol>
						</div>
					</div>
				</div>
			</div> -->

			<!-- <div class="container" id="range-of-services">
				<div class="flex flex-col items-center pb-5 pt-15 xmd:pt-2 xlg:pt-2">
					<div id="publicinjury" class="text-center">
					<div class="tracking-widest uppercase text-sitelightblue-600 font-JosefinSans md:text-xl">INJURIES AT</div>
					<div class="mb-5 text-2xl font-normal leading-none uppercase font-GildaDisplay text-sitemidblue-600 md:text-8xl"> PUBLIC /PRIVATE PREMISES</div>
					</div>
				</div>
			</div> -->
		</div>

		<div class="w-full bg-sitemidblue">
			<div class="container text-xl text-center text-white py-15">
				<p>It’s important to have a good team of lawyers and specialists support you during this difficult time. <span class="sm:inline-block md:inline xl:inline-block">The team you engage can have a significant impact on the outcome of your case and your life.</span> <span class="sm:inline-block">Please call us at <a href="tel:+6565534800" class="font-semibold text-sitelightblue"><span>6553 4800</span></a> and we will attend to you as soon as possible.</span></p>
			</div>
		</div>

		<!-- <div class="relative w-full overflow-hidden bg-white rangeofservices-section">
			<div class="container p-0 mx-auto">
				<div class="flex flex-wrap overflow-hidden sm:-mx-10">
					<div class="w-full overflow-hidden sm:px-10 lg:w-1/2">
						<article class="pl-4 pr-4 my-16 lg:pl-8 lg:pr-0 lg:my-20">
							<div class="mb-10 text-xl font-semibold text-left font-JosefinSans text-sitemidblue md:text-3xl">
							1. Falling Objects (E.g. Building tiles, Structure panels, Roof, Tree branch)
							</div>
							<div class="mb-5 text-xl font-semibold font-JosefinSans text-sitemidblue md:text-3xl">
							2. Slip and Fall cases
							</div>
							<ul class="flex flex-wrap mb-10 mylisting">
								<li class="w-full mb-2 md:w-1/2 pl-7 list-igray -disc">Wet floors in shopping malls, supermarkets, and restaurants</li>
							</ul>
							<div class="mb-5 text-xl font-semibold font-JosefinSans text-sitemidblue md:text-3xl">
							3. Trip and Fall cases
							</div>
							<ul class="flex flex-wrap mb-10 mylisting">
								<li class="w-full mb-2 md:w-full pl-7 list-igray -disc">Buttress roots along pavements</li>
								<li class="w-full mb-2 md:w-full pl-7 list-igray -disc">Broken tiles</li>
							</ul>
							<div class="mb-5 text-xl font-semibold font-JosefinSans text-sitemidblue md:text-3xl">
							4. Injuries caused by Animals (E.g. Dogs, Wild boar)
							</div>
						</article>
					</div>
					<div class="w-full overflow-hidden sm:px-10 lg:w-1/2 min-603-height mb-range-of-services">
					</div>
				</div>
			</div>
		</div> -->

		<div class="w-full bg-white">
			<div class="container py-15">
				<div id="medicalnegligence" class="mb-10 text-2xl font-normal leading-none text-center uppercase text-sitemidblue font-GildaDisplay md:text-8xl">
					Our Mission
				</div>
				<div class="w-full pb-10">
					<img src="~@/assets/images/img03.jpg" alt="" class="rounded-md md:rounded-lg" width="1230" height="442" loading="lazy" >
				</div>
				<div class="flex flex-col lg:flex-row">
					<div class="w-full leading-8">
						<ol class="pl-4" style="list-style:disc;">
							<li>Serve the Community, the People and the Masses with Integrity</li>
							<li>Provide Affordable Legal Services</li>
							<li>Make Ourselves Conveniently Accessible to Those Who Need Us</li>
							<li>Deliver Quality Services</li>
						</ol>
					</div>
					<!-- <div class="w-full leading-8">
						<ol class="pl-4" style="list-style:disc;">
							<li>Poor follow up or aftercare</li>
							<li>Failure to warn patients of risks involved in surgery</li>
							<li>Failure to run sufficient/proper tests on patient</li>
						</ol>
					</div> -->
				</div>
			</div>
		</div>

		<div class="w-full bg-sitelightblue-400">
			<div class="container py-15">
				<div class="space-y-8">
					<p class="text-center">What our clients say</p>
					<carousel
						class=""
						:items="1"
						:dots="true"
						:nav="false"
						:loop="true"
						:autoplay="true"
						:autoHeight="false"
						:autoplaySpeed="500"
						:autoplayTimeout="5000"
					>

						<div>
							<div class="relative inline-block w-full px-10 text-base leading-tight text-center font-GildaDisplay lg:text-lg xl:text-3xl text-te-papa-green lg:px-40 quote">
								<span class="absolute top-0 left-0 w-10 xl:w-auto lg:w-15">
									<picture>
										<source type="image/webp" srcset="~@/assets/images/quote-left.webp">
										<img src="~@/assets/images/quote-left.png" width="91" height="62" loading="lazy" >
									</picture>
								</span>
								<span class="absolute top-0 right-0 w-10 xl:w-auto lg:w-15">
									<picture>
										<source type="image/webp" srcset="~@/assets/images/quote-right.webp">
										<img src="~@/assets/images/quote-right.png" width="91" height="62" loading="lazy" >
									</picture>
								</span>
								Excellent service provided even before engaging them. They answered my questions patiently with professional knowledge. Highly recommended!
								<span class="block mt-5 text-base font-JosefinSans lg:text-lg">Rachel Ang</span>
							</div>
						</div>

						<div>
							<div class="relative inline-block w-full px-10 text-base leading-tight text-center font-GildaDisplay lg:text-lg xl:text-3xl text-te-papa-green lg:px-40 quote">
								<span class="absolute top-0 left-0 w-10 xl:w-auto lg:w-15">
									<picture>
										<source type="image/webp" srcset="~@/assets/images/quote-left.webp">
										<img src="~@/assets/images/quote-left.png" width="91" height="62" loading="lazy" >
									</picture>
								</span>
								<span class="absolute top-0 right-0 w-10 xl:w-auto lg:w-15">
									<picture>
										<source type="image/webp" srcset="~@/assets/images/quote-right.webp">
										<img src="~@/assets/images/quote-right.png" width="91" height="62" loading="lazy" >
									</picture>
								</span>
								Ever so patient and ready to answer all my questions. Thank you for the excellent service rendered during this difficult period. Greatly appreciate your professionalism and you have made it a less painful process for me and family.
								<span class="block mt-5 text-base font-JosefinSans lg:text-lg">Cheryl Jeanne Goh</span>
							</div>
						</div>

						<div>
							<div class="relative inline-block w-full px-10 text-base leading-tight text-center font-GildaDisplay lg:text-lg xl:text-3xl text-te-papa-green lg:px-40 quote">
								<span class="absolute top-0 left-0 w-10 xl:w-auto lg:w-15">
									<picture>
										<source type="image/webp" srcset="~@/assets/images/quote-left.webp">
										<img src="~@/assets/images/quote-left.png" width="91" height="62" loading="lazy" >
									</picture>
								</span>
								<span class="absolute top-0 right-0 w-10 xl:w-auto lg:w-15">
									<picture>
										<source type="image/webp" srcset="~@/assets/images/quote-right.webp">
										<img src="~@/assets/images/quote-right.png" width="91" height="62" loading="lazy" >
									</picture>
								</span>
								I had an urgent matter and needed it to be settled quickly. I went to HOH because the fees were lower than I have expected. They also gave me good advice and processed my matter quickly, keeping me updated on my matter. My lawyer was helpful and will always reply to my messages reassuringly.
								<span class="block mt-5 text-base font-JosefinSans lg:text-lg">Lynette Tham</span>
							</div>
						</div>

					</carousel>
				</div>
				
			</div>
		</div>

		<!-- <div class="w-full bg-white">
			<div class="container pt-16 pb-16 mx-auto">
				<div class="mb-5 text-2xl font-normal leading-none text-center uppercase text-sitemidblue font-GildaDisplay md:text-8xl">
					Some of Our Featured Cases
				</div>
				<div class="flex flex-col lg:flex-row">
					<div class="w-full leading-8">
						<ol class="pl-4" style="list-style:decimal;">
							<li><a href="https://www.hoh.com.sg/hohlawcases-motorcyclist-paralysed-" target="_blank" class="text-sitemidblue">Malaysian Motorcyclist Paralysed in Collision Gets $615,000 in Compensation</a></li>
							<li><a href="https://www.hoh.com.sg/530000-merc-aye-accident" target="_blank" class="text-sitemidblue">$530,000 For Couple Injured in AYE Reverse-Driving Accident</a></li>
							<li><a href="https://www.hoh.com.sg/hohlawcases-710000-settlement-sum" target="_blank" class="text-sitemidblue">$710,000 Settlement Sum for Lorry Driver Who Fell From Height In Workplace Accident</a></li>
						</ol>
					</div>
					<div class="w-full leading-8">
						<ol class="pl-4" style="list-style:decimal;" start="4">
							<li><a href="https://www.hoh.com.sg/hohlawcases-worker-struck-on-head" target="_blank" class="text-sitemidblue">Worker Struck on Head and Left Wheelchair Bound - He Gets $700,000</a></li>
							<li><a href="https://www.hoh.com.sg/hohlawcases-tree-branch-causes-para" target="_blank" class="text-sitemidblue">Tree Branch Causes Paralysis in Woman - She Gets $500,000</a></li>
							<li><a href="https://www.hoh.com.sg/hohlawcases-97000-for-man-hit-on-he" target="_blank" class="text-sitemidblue">$97,000 For Man Hit on Head by Boss</a></li>
						</ol>
					</div>
				</div>
				<div class="w-full pt-4 leading-8 text-red-500">
					[Click on the titles to read more]
				</div>
			</div>
		</div> -->

		<div class="w-full bg-sitelightblue-400">
			<!--Form Section-->
			<div class="w-full gradient-r">
				<div class="container pb-5 mx-auto pt-15 md:pt-16 md:pb-30" id="enquiry">
					<div class="flex flex-col items-center flex-between md:flex-row" id="arrange-appointment">
						<div class="text-center md:text-left">
						<div class="pb-4"><img src="~@/assets/images/logo-white.png" alt="" class="mx-auto lg:mx-0" width="159" height="77" loading="lazy" ></div>
						<div class="mb-5 font-normal leading-none text-white uppercase font-GildaDisplay md:text-2xl">Call our hotline today at<span class="inline md:block"></span></div>
						<div class="text-2xl leading-tight tracking-widest text-white uppercase font-JosefinSans md:text-7xl"><a href="tel:+6565534800" class="text-6xl font-semibold text-siteblue md:text-8xl"><span>6553 4800</span></a> or<br/>WhatsApp us at <a href="https://api.whatsapp.com/send?phone=6580306507&text=I%20would%20like%20to%20enquire" class="text-6xl font-semibold text-siteblue md:text-8xl" target="_blank">8030&zwj; 6507</a></div>
						</div>
						<div class="relative z-10 flex-shrink-0 mx-auto rounded-full shadow-xl w-28 h-28 md:w-45 md:h-45 mt-7 md:mt-0 md:ml-auto md:mr-0 bg-sitemidblue">
							<img src="~@/assets/images/24-hrs.png" class="w-full" alt="" width="199" height="199" loading="lazy" >
						</div>
					</div>
				</div>
			</div>
			<div class="container pb-15">
				<div class="relative px-8 pb-12 rounded-lg bg-sitelightblue pt-15 -mt-18 md:px-11">
					<ContactForm />
					<!-- feedback.activamedia.com.sg script begins here -->
					<!-- <iframe allowTransparency="true" style="min-height:425px; height:inherit; overflow:auto;" width="100%" id="contactform123" name="contactform123" marginwidth="0" marginheight="0" frameborder="0" src="https://feedback.activamedia.com.sg/my-contact-form-5697638.html"></iframe> -->
					<!-- feedback.activamedia.com.sg script ends here -->
				</div>
			</div>
			<!--Form Section Ends-->
		</div>

		<div class="w-full bg-white">
			<div class="container pt-16 pb-16 mx-auto">
				<div class="mb-10 text-2xl font-normal leading-none text-center uppercase text-sitemidblue font-GildaDisplay md:text-8xl">
					AN INFORMATIVE VIDEO ON THE LEGAL SERVICES WE PROVIDE
				</div>
				<div class="flex flex-col lg:flex-row">
					<div class="w-full">
						<video width="100%" loop controls :poster="require('@/assets/images/video-poster.jpg')">
							<source src="//commondatastorage.googleapis.com/activamedia/hoh-law-introduction.mp4" type="video/mp4">
						</video>
					</div>
				</div>
			</div>
		</div>

		<SiteFooter />

	</div>
</template>

<script>
// @ is an alias to /src
import SiteHeader from '@/components/SiteHeader.vue'
import SiteFooter from '@/components/SiteFooter.vue'
import ContactForm from '@/components/ContactForm.vue'

export default {
	name: 'index',
	components: {
		SiteHeader,
		SiteFooter,
		ContactForm,
	}
}
</script>

<style>
html {
	box-sizing: border-box;
}
*,*:before,*:after {
	box-sizing: inherit;
}
@-ms-viewport {
	width: device-width;
}
*:focus {
	outline:none !important
}
* {
	@apply outline-none;
	-webkit-tap-highlight-color: transparent;
}
body:not(.user-is-tabbing) button:focus,
body:not(.user-is-tabbing) input:focus,
body:not(.user-is-tabbing) select:focus,
body:not(.user-is-tabbing) textarea:focus {
	outline: none;
}
html, body{
	margin: 0;
	font-family: 'Source Sans Pro', sans-serif;
	font-weight: normal;
	/* overflow-x: hidden; */
	font-size: 18px;
	letter-spacing: 0.025em;
	color: #000;
	word-spacing: 1px;
	@apply antialiased subpixel-antialiased;
	box-sizing: border-box;
	/* scroll-behavior: smooth; */
}

/* @media screen and (prefers-reduced-motion: reduce) {
	html,body {
		scroll-behavior: smooth;
	}
} */

.max-width-1400 {
	max-width: 1400px!important;
}
.min-603-height {
	height: 400px;
}

.gradient-r {
	background: rgb(29,81,115);
	background: -moz-linear-gradient(25deg, #156b33 0%, #0f4622 100%);
	background: -webkit-linear-gradient(25deg, #156b33 0%, #0f4622 100%);
	background: linear-gradient(25deg, #156b33 0%, #0f4622 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#156b33",endColorstr="#0f4622",GradientType=1);
}

.gradient-l {
	background: rgb(29,81,115);
	background: -moz-linear-gradient(25deg, #0f4622 0%, #156b33 100%);
	background: -webkit-linear-gradient(25deg, #0f4622 0%, #156b33 100%);
	background: linear-gradient(25deg, #0f4622 0%, #156b33 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0f4622",endColorstr="#156b33",GradientType=1);
}

header {
	z-index: 0;
	background-color: #101c2c;
	min-height: 613px;
}
header:before {
	content: "";
	position: absolute;
	top: 0;
	right: 0;
	height: 100%;
	width: 100%;
	z-index: -1;
	background: url('~@/assets/images/image001m.jpg') right center no-repeat;
	background-size: cover;
}
@screen lg {
	header {
		min-height: 703px;
	}
	header:before {
		content: "";
		position: absolute;
		top: 0;
		right: 0;
		height: 100%;
		width: 100%;
		z-index: -1;
		background: url('~@/assets/images/image001.jpg') right center no-repeat;
		background-size:cover;
	}
}

@screen lg {
	.rangeofservices-section:before {
		content:"";
		position:absolute;
		/* background:url('~@/assets/images/image3456.jpg') center; */
		background-size:cover;
		width:50%;
		height: 100%;
		top:0;
		right:0;
	}
}
.call-button {
	background: url('/images/phone-icon2.png') center no-repeat #C7D3DB;
	background-size: contain;
}
.mb-range-of-services {
	/* background:url('~@/assets/images/image3456.jpg') center; */
	background-size:cover;
}
@screen lg {
	.mb-range-of-services {
		background:none;
	}
}
.accordion-container .accordion-item {
	cursor: pointer;
	transition: 0.3s;
	@apply w-full mb-2;
}
.accordion-container .accordion-item .accordion-title {
	@apply relative py-5 border-b;
}
.accordion-container .accordion-item .accordion-title .arrow {
	width: 17px;
	height: 10px;
	position: relative;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
	-webkit-transform-origin: 50% 50%;
	transform-origin: 50% 50%;
}
.accordion-container .accordion-item .accordion-title .arrow img {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom:0;
}
.accordion-container .accordion-item .accordion-title.active .arrow {
	-webkit-transform: rotate(180deg);
	transform: rotate(180deg);
}
.accordion-container .accordion-item .accordion-content {
	@apply py-5 border-t-0 w-full relative;
	transition: .5s;
}

.mylisting {
	list-style: none;
	list-style-type: none;
	list-style-position: none;
}
.mylisting li.-circle,.mylisting .-circle {
	position: relative;
}
.mylisting li.-circle:before,.mylisting .-circle:before {
	content: "";
	position: absolute;
	left: 0;
	top: 10px;
	height: 6px;
	width: 6px;
	border: 1px solid #fff;
	border-radius: 10px;
	margin: auto 0;
}
.mylisting li.-disc,.-disc {
	position: relative;
}
.mylisting li.-disc:before,.mylisting .-disc:before {
	content: "";
	position: absolute;
	left: 0;
	top: 10px;
	height: 6px;
	width: 6px;
	background: #fff;
	border-radius: 10px;
	margin: auto 0;
}
.mylisting li.-disc:before,.mylisting .-disc.list-igray:before {
	@apply bg-sitelightblue-700;
}
.mylisting li.-disc:before,.mylisting .-disc.list-iwhite:before {
	@apply bg-white;
}

iframe#contactform123 {
	min-height: 612px !important;
}
@screen lg {
	iframe#contactform123 {
		min-height: 450px !important;
	}
}
</style>