<template>
	<div id="site-header" class="z-20 py-3 duration-200 border-b border-t-3" :class="{'min shadow-md': !isTop}">
		<div class="container flex justify-between">
			<a href="/">
				<img src="@/assets/images/logo.png" alt="HOH Law Corporation logo" class="w-32 h-auto mr-auto lg:w-47" width="248" height="60">
			</a>
			<div class="flex flex-wrap gap-4">
				<a href="https://api.whatsapp.com/send/?phone=6580306507&text&type=phone_number&app_absent=0" target="_blank" class="flex flex-col self-center my-auto ml-auto leading-tight text-white uppercase whatsapp-us font-JosefinSans min-h-[44px]">
					<div class="hidden sm:block text-xs text-black">WhatsApp Us at</div>
					<span class="hidden sm:inline text-xl font-semibold text-black lg:text-2xl">8030&zwj; 6507</span>
				</a>
				<a href="tel:+6565534800" class="flex flex-col self-center my-auto ml-auto leading-tight text-white uppercase call-us font-JosefinSans min-h-[44px]">
					<div class="hidden sm:block text-xs text-black">call us at</div>
					<span class="hidden sm:inline text-xl font-semibold text-black lg:text-2xl">6553 4800</span>
				</a>
			</div>
		</div>
		<!-- <div class="container py-3">
			<div class="flex flex-wrap items-center justify-between h-full header-inner">
				<div class="flex items-center justify-center w-1/2 h-full mb-2 lg:justify-start lg:mb-0">
					<img class="object-contain duration-200 logo" src="@/assets/images/logo.png" alt="HOH Law Corporation logo">
				</div>
				<div class="flex items-center justify-end w-1/2 gap-x-8">
					<div class="leading-snug text-center">
						<span class="text-sm leading-normal sm:text-lg">Call us at</span>
						<br>
						<a href="tel:+6596245241" class="box-border text-xl duration-200 border-b border-transparent text-siteGreen hover:border-siteGreen">
							<IconPhone class="inline pb-1 sm:hidden" />
							9624 5241
						</a>
					</div>
					<div class="hidden sm:block">
						<a href="/#contact-form" v-if="this.$route.name!='index'" class="block px-4 py-2 text-white duration-200 bg-siteGreen hover:bg-siteGreen-2">Enquire Now</a>
						<a href="#contact-form" v-else v-smooth-scroll class="block px-4 py-2 text-white duration-200 bg-siteGreen hover:bg-siteGreen-2">Enquire Now</a>
					</div>
				</div>
			</div>
		</div> -->
	</div>
</template>

<script>
import IconPhone from '@/components/svg/IconPhone.vue'
export default {
	components: {
		IconPhone,
	},
	data () {
		return {
			isTop: true,
		};
	},
	mounted () {
		document.addEventListener('scroll', this.handleScroll, {passive: true});
	},
	methods: {
		handleScroll() {
			if (window.pageYOffset >= 250) {
				if (this.isTop) this.isTop = false;
			} else if ( document.documentElement.scrollTop < 100 ) {
				if (!this.isTop) this.isTop = true;
			}
		},
	},
}
</script>

<style scoped>
#site-header {
	@apply sticky top-0;
	border-top-color: #76B78D;
	border-bottom-color: #1A242B;
	/* height: 5rem; */
	background-color: white;
	backdrop-filter: blur(4px);
}
@media (min-width: 1024px) {
	#site-header.min {
		background-color: rgba(255,255,255,.75);
	}
}

.call-us {
	padding-left: 50px;
	background: url('~@/assets/images/phone-icon.png') left center no-repeat;
	background-size: contain;
}

.whatsapp-us {
	padding-left: 64px;
	background: url('~@/assets/images/whatsapp-icon.png') left center no-repeat;
	background-size: contain;
}

/* @screen lg {
	#site-header {
		height: 5.5rem;
	}
}
@screen xl {
	#site-header {
		height: 7.5rem;
	}
} */

.header-inner {
	/* height: 120px; */
}
.logo {
	/* min-height: 3rem;
	max-height: 4rem;
	height: 48px; */
}
</style>
