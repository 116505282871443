<template>
	<footer id="site-footer" class="flex-shrink-0 mt-auto gradient-l">
		<!-- <a href="https://api.whatsapp.com/send?phone=6597366451" id="wa-btn" class="hover:!bg-siteGreen duration-200" target="_blank">
			<img src="@/assets/images/whatsapp.png" alt="WhatsApp" class="mt-4 mx-auto" width="28" height="28">
		</a> -->

		<div class="container text-white">
			<div class="pb-10 text-md text-center pt-10 md:pt-10">
				Website maintained by Activa Media. All rights reserved.
			</div>
		</div>
		<!-- <div class="container px-4 py-2 text-center text-white lg:py-4">
			<div class="text-sm leading-tight">
				Website maintained by <br class="sm:hidden"><a href="https://www.activamedia.com.sg/" target="_blank" class="hover:underline">Activa Media</a>. All Rights Reserved.
			</div>
		</div> -->
	</footer>
</template>

<script>
export default {

}
</script>

<style>
	#wa-btn {
		position: fixed;
		width: 60px;
		height: 60px;
		bottom: 40px;
		right: 40px;
		background-color: #25d366;
		color: #FFF;
		border-radius: 50px;
		text-align: center;
		font-size: 30px;
		box-shadow: 2px 2px 3px #999;
		z-index: 100;
	}
</style>
